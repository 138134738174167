import React from 'react'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import * as GlobalStyles from 'src/styles/global.module.scss'
import { PrismicLinkType } from 'src/typings/generated/graphql'
import sauceWave from 'src/images/sauce-wave-short.png'
import retroBg from 'src/images/backgrounds/fundraiser-retro-bg.png'
import * as Styles from './fundraiserStatGrid.module.scss'

export type GridContent = {
  buttonLink: PrismicLinkType
  buttonText: string
  heading?: string
  stats?: any[]
}

type Props = {
  content: GridContent
}

const FundraiserStatGrid = ({ content }: Props) => {
  const { buttonLink, buttonText = '', heading = '', stats } = content

  const linkTarget = buttonLink?.target ? '_blank' : '_self'

  return (
    <section className={Styles.statGrid}>
      <div className={Styles.statGridInner}>
        <div className={Styles.headingContainer}>
          <div className={Styles.eyebrow}>
            <Eyebrow />
          </div>
          {heading && <h2>{heading}</h2>}
        </div>
        <div className={Styles.statContainer}>
          {stats &&
            stats?.map(stat => (
              <div key={stat.stat_headline} className={Styles.stat}>
                <h2>{stat.stat_headline}</h2>
                <p>{stat.stat_description}</p>
              </div>
            ))}
        </div>
        {buttonLink && (
          <a
            className={`${GlobalStyles.redGhostButton} ${Styles.ctaButton}`}
            href={`${buttonLink.url}`}
            target={linkTarget}
            rel="noreferrer"
          >
            {buttonText}
          </a>
        )}
      </div>
      <img
        src={retroBg}
        alt="Raising Canes Retro Background"
        className={Styles.retroBackground}
      />
      <img
        src={sauceWave}
        alt="Raising Canes Sauce Wave"
        className={Styles.sauceWave}
      />
    </section>
  )
}

export default FundraiserStatGrid
