import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './fundraiserHero.module.scss'

enum FundraiserHeroTitleSize {
  small = 'small',
  large = 'large',
}

export type FundraiserHeroContent = {
  anchor?: string
  eyebrowText?: string
  title?: string
  titleSize?: string
  titleAsH1?: boolean
  showCountdown?: boolean
  description?: string
  image?: IGatsbyImageData
  imageAlt?: string
  mobileImage?: IGatsbyImageData
  mobileImageAlt?: string
  eventDate?: string
}

type Props = {
  content: FundraiserHeroContent
}

const FundraiserHero = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    eyebrowText = '',
    title = '',
    titleSize = FundraiserHeroTitleSize.large,
    titleAsH1 = true,
    description,
    image,
    imageAlt = '',
    mobileImage,
    mobileImageAlt = '',
    eventDate = '',
    showCountdown,
  } = content

  const [daysValue, setDaysValue]: any = useState(0)
  const [hoursValue, setHoursValue]: any = useState(0)
  const [minutesValue, setMinutesValue]: any = useState(0)
  const [secondsValue, setSecondsValue]: any = useState(0)

  let desktopImageClass = Styles.image
  if (!mobileImage) desktopImageClass += ` ${Styles.desktopImage}`

  let titleClass = Styles.title
  if (titleSize.toLowerCase() === FundraiserHeroTitleSize.small)
    titleClass += ` ${Styles.small}`

  // THESE TIME CALCULATION UTILITY FUNCTIONS WERE INSPIRED BY THIS
  // STACK OVERFLOW JUST MODIFIED FOR REACT CONTEXT - https://stackoverflow.com/a/13904120

  function getTimeRemaining(endtime: any) {
    const t = endtime - new Date().getTime()
    const seconds = Math.floor((t / 1000) % 60)
    const minutes = Math.floor((t / 1000 / 60) % 60)
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
    const days = Math.floor(t / (1000 * 60 * 60 * 24))
    return {
      total: t,
      days,
      hours,
      minutes,
      seconds,
    }
  }

  function initializeClock(endtime: any) {
    let timeinterval: any

    function updateClock() {
      const totalTime = getTimeRemaining(endtime)
      const eventExpired = totalTime.total < 0
      const daysRemaining = eventExpired ? '0' : totalTime.days
      const hoursRemaining = eventExpired
        ? '0'
        : `0${totalTime.hours}`.slice(-2)
      const minutesRemaining = eventExpired
        ? '0'
        : `0${totalTime.minutes}`.slice(-2)
      const secondsRemaining = eventExpired
        ? '0'
        : `0${totalTime.seconds}`.slice(-2)

      setDaysValue(daysRemaining)
      setHoursValue(hoursRemaining)
      setMinutesValue(minutesRemaining)
      setSecondsValue(secondsRemaining)

      if (totalTime.total <= 0) {
        clearInterval(timeinterval)
      }
    }

    updateClock()
    timeinterval = setInterval(updateClock, 1000)
  }

  function convertDateForIos(date: any) {
    const arr = date.split(/[- :]/)
    const newDate = new Date(arr[2], arr[0] - 1, arr[1])
    return newDate
  }

  useEffect(() => {
    const formattedDate = convertDateForIos(eventDate)
    const deadline = formattedDate.getTime()
    initializeClock(deadline)
  }, [])

  return (
    <section id={anchor} className={Styles.fundraiserHero}>
      <div className={Styles.backgroundImageWrapper}>
        {!!image && (
          <GatsbyImage
            className={desktopImageClass}
            image={image}
            alt={imageAlt}
          />
        )}
        {!!mobileImage && (
          <GatsbyImage
            className={`${Styles.image} ${Styles.mobileImage}`}
            image={mobileImage}
            alt={mobileImageAlt}
          />
        )}
      </div>
      <div className={Styles.overlay} />
      <div className={Styles.content}>
        <Eyebrow className={Styles.eyebrow} />
        <p>{eyebrowText}</p>
        {titleAsH1 && (
          <h1 className={titleClass}>{BertholdTitle({ text: title })}</h1>
        )}
        {!titleAsH1 && (
          <h2 className={titleClass}>{BertholdTitle({ text: title })}</h2>
        )}
        {description && <p className={Styles.heroDescription}>{description}</p>}
        {showCountdown && (
          <div className={Styles.countDown}>
            <div>
              <h2 className={Styles.timeValue}>{daysValue}</h2>
              <p>Days</p>
            </div>
            <span className={Styles.timeDivider} />
            <div>
              <h2 className={Styles.timeValue}>{hoursValue}</h2>
              <p>Hours</p>
            </div>
            <span className={Styles.timeDivider} />
            <div>
              <h2 className={Styles.timeValue}>{minutesValue}</h2>
              <p>Minutes</p>
            </div>
            <span className={Styles.timeDivider} />
            <div>
              <h2 className={Styles.timeValue}>{secondsValue}</h2>
              <p>Seconds</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default FundraiserHero
