// extracted by mini-css-extract-plugin
export var backgroundImageWrapper = "fundraiserHero-module--background-image-wrapper--fff0f";
export var content = "fundraiserHero-module--content--8f138";
export var countDown = "fundraiserHero-module--countDown--1f17f";
export var desktopImage = "fundraiserHero-module--desktop-image--db484";
export var eyebrow = "fundraiserHero-module--eyebrow--c5e1b";
export var fundraiserHero = "fundraiserHero-module--fundraiser-hero--5ee99";
export var heroDescription = "fundraiserHero-module--heroDescription--27942";
export var image = "fundraiserHero-module--image--5cbf9";
export var mobileImage = "fundraiserHero-module--mobile-image--04c2e";
export var overlay = "fundraiserHero-module--overlay--2930a";
export var small = "fundraiserHero-module--small--2ec82";
export var timeDivider = "fundraiserHero-module--timeDivider--80920";
export var timeValue = "fundraiserHero-module--timeValue--94396";
export var title = "fundraiserHero-module--title--334b1";