import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { useState } from 'react'
import LocationsMap from 'src/components/molecules/locationsMap'
import { WithContext, Event } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'
import Calendar from 'src/images/svgs/calendar.svg'
import LocationPin from 'src/images/svgs/locationpin.svg'
import OneLoveGroup from 'src/images/one-love-group.png'
import Globe from 'src/images/globe.png'

import { IFundraiser } from 'src/services/creatioService'
import * as GlobalStyles from 'src/styles/global.module.scss'
import {
  FundraiserPageQuery,
  PrismicLinkType,
  PrismicStoreLocation,
  PrismicStructuredTextType,
} from 'src/typings/generated/graphql'

import { CustomLinkButton } from 'src/utils/customLink'
import { getStoreLocationDisplayAddress } from 'src/utils/storeLocationHelper'
import Toaster from 'src/components/atoms/toaster'

import FundraiserHero from 'src/components/organisms/heroes/fundraiserHero'
import ImageReelFundraiser from 'src/components/organisms/ctas/imageReelFundraiser'
import FundraiserStatGrid from 'src/components/organisms/slidersAndGrids/fundraiserStatGrid'
import { VerticalImageReelImages } from 'src/components/molecules/verticalImageReel'
import * as Styles from './fundraiserTemplate.module.scss'

const FundraiserTemplate = ({
  data,
  pageContext,
}: PageProps<FundraiserPageQuery, IFundraiser>) => {
  const { urlPdf, urlBWPdf, eventSlug } = pageContext

  const globalContent = data.prismicGlobalContent?.data
  const location = data.prismicStoreLocation as PrismicStoreLocation
  const locationAddress = location
    ? getStoreLocationDisplayAddress(location)
    : ''
  const locationCoordinates = location
    ? {
        lat: location.data.coordinates?.latitude ?? 0,
        lng: location.data.coordinates?.longitude ?? 0,
      }
    : {
        lat: 0,
        lng: 0,
      }
  const mapLocations = location ? [location] : []

  const [toasterMessage, setToasterMessage] = useState(
    'Link copied to clipboard'
  )
  const [showToaster, setShowToaster] = useState(false)
  const fundraiserUrl = `https://raisingcanes.com/fundraiser/${eventSlug}`
  const handleCopyLinkButton = () => {
    if (typeof navigator === 'undefined') return

    navigator.clipboard
      .writeText(fundraiserUrl)
      .then(() => {
        setToasterMessage('Link copied to clipboard')
      })
      .catch(() => {
        setToasterMessage('Error, could not copy to clipboard')
      })
      .finally(() => {
        setShowToaster(true)
      })
  }

  const copyLinkButton: CustomLinkButton = {
    url: fundraiserUrl,
    onClick: handleCopyLinkButton,
  }

  const pdfLink: PrismicLinkType = {
    url: urlPdf,
    target: '_blank',
  }

  const pdfBWLink: PrismicLinkType = {
    url: urlBWPdf,
    target: '_blank',
  }

  return (
    <div className={Styles.fundraiserPage}>
      <FundraiserHero
        content={{
          eyebrowText: globalContent?.fundraiser_eyebrow_text?.text ?? '',
          title: pageContext.eventName ?? '',
          description: globalContent?.fundraiser_hero_description?.text ?? '',
          showCountdown: globalContent?.show_fundraiser_countdown ?? false,
          image: globalContent?.fundraiser_hero_image?.gatsbyImageData,
          imageAlt: globalContent?.fundraiser_hero_image?.alt ?? '',
          titleSize: 'small',
          titleAsH1: false,
          eventDate: pageContext.eventDate ?? '',
        }}
      />
      <section className={Styles.eventDetails}>
        <div className={Styles.detailsContainer}>
          <img
            className={Styles.oneLoveGroup}
            src={OneLoveGroup}
            alt="one love icon"
          />
          <img className={Styles.globeIcon} src={Globe} alt="globe icon" />
          <div className={Styles.detailsHeading}>
            <h3>Event Details</h3>
            <a
              className={`${GlobalStyles.redGhostButton} ${Styles.desktopDirectionButton}`}
              href={`https://www.google.com/maps/place/${locationAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              Get Directions
            </a>
          </div>
          <div className={Styles.eventDetailsOuter}>
            <div>
              <p className={Styles.detailsTitle}>Date & Time</p>
              <div className={Styles.eventDetailsContainer}>
                <Calendar />
                <div>
                  <p>Date: {pageContext.eventDate}</p>
                  <p>
                    Time: {pageContext.eventStart} - {pageContext.eventEnd}
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.eventDetailsAddressContainer}>
              <p className={Styles.detailsTitle}>Location</p>
              <div className={Styles.eventDetailsContainer}>
                <LocationPin />
                <div>
                  <p className={Styles.eventDetailsAddress}>
                    {locationAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a
            className={`${GlobalStyles.redGhostButton} ${Styles.mobileDirectionButton}`}
            href={`https://www.google.com/maps/place/${locationAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            Get Directions
          </a>
        </div>
        <div className={Styles.textParagraph}>
          <div className={Styles.redOverlay} />
          <div className={Styles.blackBorder} />
          <p className={Styles.eventDetailsParagraph}>
            {globalContent?.fundraiser_details_text}
          </p>
        </div>
      </section>
      <section key="details" className={Styles.fundraiserDetails}>
        <div className={Styles.details}>
          <p className={Styles.address}>{locationAddress}</p>
          <div className={Styles.buttonContainer}>
            <a
              href={`https://www.google.com/maps/place/${locationAddress}`}
              target="_blank"
              className={`${GlobalStyles.whiteGhostButtonResponsive}`}
              rel="noreferrer"
            >
              {globalContent?.fundraiser_directions_button_text}
            </a>
          </div>
        </div>
        <div key="content" className={Styles.map}>
          <LocationsMap
            locations={mapLocations}
            selectedLocationIndex={0}
            center={locationCoordinates}
            interactive={false}
            zoom={12}
          />
        </div>
      </section>
      {globalContent?.image_reel_images?.length ? (
        <ImageReelFundraiser
          content={{
            eyebrowText: globalContent?.image_reel_eyebrow?.text ?? '',
            headline: globalContent?.image_reel_header?.text ?? '',
            description:
              (globalContent?.image_reel_description as PrismicStructuredTextType) ??
              undefined,
            button1Link: copyLinkButton,
            showSocialMedia: true,
            showRetroIcons: true,
            button2Link: pdfLink,
            button3Link: pdfBWLink,
            items: globalContent?.image_reel_images?.map(
              item =>
                ({
                  image: item?.reel_image?.gatsbyImageData ?? undefined,
                  imageAlt: item?.reel_image?.alt ?? undefined,
                } as VerticalImageReelImages)
            ),
          }}
        />
      ) : (
        ''
      )}

      {globalContent?.stats?.length ? (
        <FundraiserStatGrid
          content={{
            heading: globalContent?.stat_grid_header?.text ?? '',
            stats: globalContent?.stats?.map(item => ({
              stat_headline: item?.heading?.text ?? '',
              stat_description: item?.description?.text ?? '',
            })),
            buttonLink: globalContent?.stat_grid_button_link ?? {
              url: '',
              target: '',
            },
            buttonText: globalContent?.stat_grid_button_text ?? '',
          }}
        />
      ) : (
        ''
      )}

      {showToaster && (
        <Toaster
          message={toasterMessage}
          onClose={() => {
            setShowToaster(false)
          }}
        />
      )}
    </div>
  )
}

export default FundraiserTemplate

export const Head = ({
  pageContext,
}: HeadProps<FundraiserPageQuery, IFundraiser>) => {
  const title = pageContext.eventName ?? 'Fundraiser'
  const description = `Visit a Raising Cane's and mention the "${pageContext.eventName}" fundraiser at the register and Cane's will donate a portion of the sale to the fundraiser!`
  const url = pageContext.eventSlug
    ? `/fundraiser/${pageContext.eventSlug}`
    : ''

  const schema: WithContext<Event> = {
    '@context': 'https://schema.org',
    '@type': 'Event',
    url,
    name: title,
    description,
  }

  return DocumentHead({
    title,
    url,
    description,
    imageUrl: '',
    pageSchema: schema,
  })
}

export const query: any = graphql`
  query FundraiserPage($storeCode: String) {
    prismicStoreLocation(uid: { eq: $storeCode }) {
      data {
        coordinates {
          latitude
          longitude
        }
        external_location_data {
          address1
          address2
          city
          state
          postal_code
        }
        override_external_hours
        override_external_phone
        override_external_address_1
        override_external_address_2
        override_external_city
        override_external_state
        override_external_zip_code
      }
    }
    prismicGlobalContent {
      data {
        fundraiser_hero_text
        fundraiser_hero_image {
          gatsbyImageData
          alt
        }
        fundraiser_details_text
        fundraiser_directions_button_text
        fundraiser_cta_header
        fundraiser_cta_description {
          richText
        }
        fundraiser_cta_copy_url_button_text
        fundraiser_cta_image {
          gatsbyImageData
          alt
        }
        fundraiser_pdf_download_header
        fundraiser_pdf_download_image {
          gatsbyImageData
          alt
        }
        fundraiser_pdf_download_link {
          ...BasicLinkFragment
        }
        fundraiser_pdf_download_link_text
        image_reel_eyebrow {
          text
        }
        image_reel_header {
          text
        }
        image_reel_description {
          html
          raw
          richText
          text
        }
        image_reel_images {
          reel_image {
            gatsbyImageData
            alt
          }
        }
        stat_grid_button_text
        stat_grid_button_link {
          url
          target
        }
        stat_grid_header {
          text
        }
        stats {
          description {
            text
          }
          heading {
            text
          }
        }
        fundraiser_eyebrow_text {
          text
        }
        fundraiser_hero_description {
          text
        }
        show_fundraiser_countdown
      }
    }
  }
`
