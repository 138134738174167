import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import * as React from 'react'
import {
  PrismicLinkType,
  PrismicSiteInformationDataSocialMediaPlatforms,
  PrismicStructuredTextType,
  SocialMediaQuery,
} from 'src/typings/generated/graphql'
import VerticalImageReel, {
  VerticalImageReelDirection,
  VerticalImageReelImages,
} from 'src/components/molecules/verticalImageReel'
import BertholdTitle from 'src/components/atoms/bertholdTitle'
import * as GlobalStyles from 'src/styles/global.module.scss'
import CustomLink from 'src/utils/customLink'
import CaniacClubCardIcon from 'src/images/svgs/icons/retro/CaniacClubCardIcon.svg'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import VideoPauseIcon from 'src/images/icons/VideoPauseIcon.webp'
import VideoPlayIcon from 'src/images/icons/VideoPlayIcon.webp'
import { generateRandomId } from 'src/utils/domHelper'
import * as Styles from './imageReel.module.scss'

enum ImageReelBackgroundStyle {
  red = 'red',
  gray = 'gray',
}

export type ImageReelContent = {
  anchor?: string
  eyebrowText?: string
  headline?: string
  description?: PrismicStructuredTextType
  button1Link?: PrismicLinkType
  button1Text?: string
  button2Link?: PrismicLinkType
  button2Text?: string
  button3Link?: PrismicLinkType
  button3Text?: string
  backgroundStyle?: string
  showRetroIcons?: boolean
  showSocialMedia?: boolean
  socialMediaHashtag?: string
  items?: VerticalImageReelImages[]
}

type Props = {
  content: ImageReelContent
}

const ImageReelFundraiser = ({ content }: Props) => {
  const {
    anchor = generateRandomId(),
    eyebrowText = '',
    headline = '',
    description,
    button1Link,
    button1Text = 'Copy Fundraiser Link',
    button2Link,
    button2Text = 'Download PDF',
    button3Link,
    button3Text = 'Download PDF (black and white)',
    backgroundStyle = ImageReelBackgroundStyle.red,
    showRetroIcons = false,
    showSocialMedia = '',
    socialMediaHashtag = '',
    items = [],
  } = content

  const backgroundStyleFormatted = backgroundStyle.toLowerCase()
  const reel1: Array<VerticalImageReelImages> = []
  const reel2: Array<VerticalImageReelImages> = []
  const reel3: Array<VerticalImageReelImages> = []

  let counter = 1
  items.forEach(img => {
    if (counter === 1) reel1.push(img)
    else if (counter === 2) reel2.push(img)
    else reel3.push(img)

    counter = counter === 3 ? 1 : (counter += 1)
  })

  const backgroundColor =
    backgroundStyleFormatted === ImageReelBackgroundStyle.gray
      ? '#32393d'
      : '#d71c2e'

  let eyebrow: any
  if (eyebrowText) {
    eyebrow = BertholdTitle({ text: eyebrowText })
  } else {
    eyebrow = (
      <>
        <Eyebrow />
        <Eyebrow />
      </>
    )
  }

  const socialMediaData: SocialMediaQuery = useStaticQuery(graphql`
    query SocialMedia {
      prismicSiteInformation {
        data {
          social_media_platforms {
            link {
              ...BasicLinkFragment
            }
            name
            white_icon {
              gatsbyImageData(layout: FIXED, height: 20)
              alt
            }
          }
        }
      }
    }
  `)

  const socialMedia = socialMediaData.prismicSiteInformation?.data
    .social_media_platforms as PrismicSiteInformationDataSocialMediaPlatforms[]

  const [animationsPaused, setAnimationsPaused] = React.useState(false)
  const toggleAnimations = () => {
    setAnimationsPaused(!animationsPaused)
  }

  const animationsPlayOrPauseIcon = animationsPaused ? 'play' : 'pause'

  return (
    <section id={anchor} className={Styles.imageReel}>
      <div
        className={Styles.copy}
        style={{ backgroundColor: `${backgroundColor}` }}
      >
        {showRetroIcons && (
          <div className={Styles.retro}>
            <div className={Styles.caniacClubCardIcon}>
              <CaniacClubCardIcon />
            </div>
          </div>
        )}

        <div className={Styles.text}>
          <div role="heading" aria-level={2}>
            <div className={Styles.headline1}>{eyebrow}</div>
            <div className={Styles.headline2}>
              {BertholdTitle({ text: headline })}
            </div>
          </div>
          <div className={Styles.description}>
            {description && (
              <PrismicRichText
                field={description.richText}
                components={{
                  hyperlink: ({ node, children }: any) =>
                    CustomLink({ link: node.data, children }),
                }}
              />
            )}
          </div>
          <div className={Styles.buttonContainer}>
            {button1Link?.url && (
              <CustomLink
                className={GlobalStyles.whiteGhostButton}
                link={button1Link}
                data-testid="button1Link"
              >
                {button1Text}
              </CustomLink>
            )}
            {button2Link?.url && (
              <Link
                className={GlobalStyles.whiteBasicLink}
                to={button2Link.url}
                data-testid="button2Link"
                target="_blank"
              >
                {button2Text}
              </Link>
            )}
            {button3Link?.url && (
              <Link
                className={GlobalStyles.whiteBasicLink}
                to={button3Link.url}
                data-testid="button3Link"
                target="_blank"
              >
                {button3Text}
              </Link>
            )}
          </div>
          {showSocialMedia && (
            <div className={Styles.socialMediaContainer}>
              {socialMediaHashtag && (
                <div className={Styles.hashtag}>{socialMediaHashtag}</div>
              )}
              <div role="list" className={Styles.socialMediaIcons}>
                {socialMedia.map((socialMediaItem: any, index: number) => {
                  const key = `socialMedia-${index}`
                  return (
                    <CustomLink
                      key={key}
                      role="listitem"
                      link={socialMediaItem.link}
                    >
                      {socialMediaItem.white_icon.gatsbyImageData && (
                        <GatsbyImage
                          data-testid="social-media-image"
                          image={socialMediaItem.white_icon.gatsbyImageData}
                          alt={socialMediaItem.white_icon.alt ?? ''}
                        />
                      )}
                    </CustomLink>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.outerReelContainer}>
        <div className={Styles.reelContainer}>
          <VerticalImageReel images={reel1} paused={animationsPaused} />
        </div>
        <div className={Styles.reelContainer}>
          <VerticalImageReel
            images={reel2}
            direction={VerticalImageReelDirection.down}
            paused={animationsPaused}
          />
        </div>
        <div className={Styles.reelContainer}>
          <VerticalImageReel images={reel3} paused={animationsPaused} />
        </div>
        <button
          type="button"
          className={Styles.videoControl}
          onClick={() => {
            toggleAnimations()
          }}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              toggleAnimations()
            }
          }}
          aria-label={
            animationsPaused ? 'Play Image Reels' : 'Pause Image Reels'
          }
        >
          <img
            src={animationsPaused ? VideoPlayIcon : VideoPauseIcon}
            alt={`${animationsPlayOrPauseIcon} video`}
            aria-label={`image reel ${animationsPlayOrPauseIcon} video icon`}
          />
        </button>
      </div>
    </section>
  )
}

export default ImageReelFundraiser
